import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetNews } from 'hooks';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { NewsSlide } from './NewsSlide/NewsSlide';
import { NewsSlideWrapper } from './style.js';

export const NewsFeed = () => {
  const analytics = getAnalytics();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('teamId');
  const team = searchParams.get('team');

  const { status, isFetching, data } = useGetNews(teamId);

  const shouldNewsFeedRender =
    status === 'success' && !isFetching && data && !!data.data.length;

  if (status === 'error') {
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `News fetching error for team ${team} with teamId ${teamId} and site url: ${window.location.href}`,
    });
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {shouldNewsFeedRender ? (
        <NewsSlideWrapper>
          <NewsSlide news={data.data} />{' '}
        </NewsSlideWrapper>
      ) : null}
    </>
  );
};
