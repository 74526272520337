import { LOGOUT } from 'constants/names.js';
import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import types from 'redux/types';
import { clearLocalStorage } from 'helpers';

import Button from 'components/Button';
import { GoogleLogoutButton } from 'components/AuthContainer/LoginForm/GoogleLogoutButton';

import {
  SlidePanelContainer,
  CloseButton,
  Backdrop,
  FooterNotification,
  LogoutContainer,
} from './style.js';

const UserProfile = ({ isOpen, onClose }) => {
  const { firebaseToken, email } = useSelector((state) => state.user.data);
  const isUserAuth = !!useSelector((state) => state.user.data.token);
  const isGoogleId = !!useSelector((state) => state.user.data.googleId);
  const userMenuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickEsc = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  const handleClickOutside = (e) => {
    if (
      isOpen &&
      userMenuRef.current &&
      !userMenuRef.current?.contains(e.target)
    ) {
      onClose();
    }
  };

  const handleLogout = useCallback(() => {
    dispatch({
      type: types.USER_LOGIN_RESET,
    });

    dispatch({
      type: types.SET_SELECTED_TEAM,
      payload: { teamName: '', teamId: '' },
    });

    dispatch({
      type: types.SET_SELECTED_LEAGUE,
      payload: { leagueName: '', leagueId: '' },
    });

    clearLocalStorage();
    onClose();

    setTimeout(() => {
      navigate('/');
      window.location.reload();
    }, 1000);
  }, [dispatch, navigate, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleClickEsc);
    }

    return () => {
      document.removeEventListener('keydown', handleClickEsc);
    };
  });

  return (
    <>
      <Backdrop $isOpen={isOpen} />
      <SlidePanelContainer $isOpen={isOpen} ref={userMenuRef}>
        <CloseButton onClick={onClose} />
        {email ? (
          <FooterNotification>Signed in as {email}</FooterNotification>
        ) : null}

        {firebaseToken ? (
          <FooterNotification>
            * User is registered to receive web push notifications for this
            site.
          </FooterNotification>
        ) : null}

        {isUserAuth ? (
          <LogoutContainer>
            {isGoogleId ? (
              <GoogleLogoutButton />
            ) : (
              <Button onClick={handleLogout} title={LOGOUT} />
            )}
          </LogoutContainer>
        ) : null}
      </SlidePanelContainer>
    </>
  );
};

export default UserProfile;
