import { backgroundNames } from 'constants/backgroundNames';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getBackgroundName, generatePageTitle } from 'helpers';
import { changeFavicon, changeFaviconTeam } from 'helpers/changeFavicon';

import { BackgroundImg } from './style.js';

export const ThemeProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const league = searchParams.get('league');
  const collegeLeague = searchParams.get('collegeLeague');

  const { data: configuration } = useSelector((state) => state.configuration);

  //** CHANGE BACKGROUND FUNCTION */
  const loadBackgroundImage = useCallback((imgName) => {
    let imageName = '';

    if (imgName) {
      imageName = imgName;
    }

    if (imageName !== '') {
      import(`assets/img/${imageName}.jpg`)
        .then((image) => {
          setBackground(image.default);
        })
        .catch((error) => {
          loadBackgroundImage(backgroundNames.default);
          console.log(error);
        });
    }
  }, []);

  const [background, setBackground] = useState('');

  if (configuration?.display_one_team === '1') {
    //** one team display */
    changeFaviconTeam(configuration?.one_team_name);
  } else if (configuration?.display_teams_select === '1') {
    //** one league display */
    changeFavicon('favicon');
  } else if (configuration?.display_leagues_select === '1') {
    //** display all leagues and teams selection */
    changeFavicon('favicon');
  }
  //** INITIALLY SET BACKGROUND FOR DOWNKOUNT SITE **//
  useEffect(() => {
    if (!league) {
      setBackground(
        loadBackgroundImage(
          configuration?.display_leagues_select === '1'
            ? backgroundNames.default
            : ''
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration?.display_leagues_select]);

  //** SET BACKGROUND DEPENDS UPON LEAGUE SELECTED **//
  useEffect(() => {
    if (league || collegeLeague) {
      loadBackgroundImage(
        getBackgroundName(league?.toUpperCase() || collegeLeague?.toUpperCase())
      );
    }
  }, [league, collegeLeague, loadBackgroundImage]);

  //** FUNCTION TO SET PAGE TITLE AT THE BROWSER WINDOW AND META DESCRIPTION **//
  useEffect(() => {
    if (
      configuration?.site_name &&
      configuration?.sport_level &&
      configuration?.site_description &&
      configuration?.sport_type
    ) {
      generatePageTitle({
        title: `${configuration.site_name} - ${window.location.origin}`,
        metaDescription: `${configuration.site_description ? configuration.site_description : 'This countdown continues...'}`,
        //metaDescription: `${configuration.sport_level} ${configuration.sport_type} league game schedule`,
      });
    }
  }, [
    configuration?.site_name,
    configuration?.sport_type,
    configuration?.sport_level,
    configuration?.site_description,
  ]);

  //** SET BACKGROUND FOR ONE TEAM SITES **//
  useEffect(() => {
    if (configuration?.display_one_team === '1') {
      loadBackgroundImage(
        getBackgroundName(configuration?.one_league_name?.toUpperCase())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration?.display_one_team, configuration?.one_league_name]);

  return (
    <>
      {background ? <BackgroundImg $imgUrl={background} /> : null}
      {children}
    </>
  );
};
