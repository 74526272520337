import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { api } from 'api/api.js';
import { useGetCurrentEventsList } from 'hooks/useGetCurrentEventsList.js';
import { hexToRgba } from 'helpers';
import { getAnalytics, logEvent } from 'firebase/analytics';

import {
  ErrorMessage,
  Loading,
  AuthContainer,
  CustomEventsHeader,
} from 'components';
import { DashboardCustomEvents } from 'components/Dashboard/DashboardCustomEvents';

import {
  BodyContainer,
  MainContainer,
  StyledHeader,
  StyledFooter,
} from 'components/App/style';

const CustomEvents = () => {
  const analytics = getAnalytics();
  const [searchParams] = useSearchParams();
  const typeId = searchParams.get('typeId');

  const { data: configData } = useSelector((state) => state.configuration);

  const {
    status: eventsListStatus,
    data: eventsList,
    isLoading: isEventsListLoading,
  } = useQuery(
    ['getEventsList', typeId],
    () => api.customEvents.getSelectedEvent(typeId),
    {
      onError: () => {
        toast.error(
          'An error occurred while loading selected event list. Please try again later.'
        );

        logEvent(analytics, 'select_content', {
          content_type: 'error',
          content_id: `Unable to load custom events list for site: ${window.location.href}`,
        });
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const { currentEventIndex, currentEventsList } = useGetCurrentEventsList(
    eventsListStatus === 'success' ? eventsList.data : []
  );

  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    if (configData?.style_main_color) {
      setBackgroundColor(configData?.style_main_color);
    }
  }, [configData?.style_main_color]);

  return (
    <BodyContainer
      $backgroundColor={backgroundColor ? hexToRgba(backgroundColor) : null}
    >
      <StyledHeader>
        <AuthContainer />
        <CustomEventsHeader />
      </StyledHeader>

      <MainContainer>
        {isEventsListLoading ? <Loading /> : null}

        {eventsListStatus === 'success' && eventsList.data.length === 0 ? (
          <ErrorMessage title="Oops... It seems there are no events. Try to select another event type." />
        ) : null}

        {eventsListStatus === 'success' && !!currentEventsList ? (
          <DashboardCustomEvents currentEvent={0} events={currentEventsList} />
        ) : null}
      </MainContainer>
      <StyledFooter />
    </BodyContainer>
  );
};

export default CustomEvents;
