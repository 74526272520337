import { LOGIN } from 'constants/names.js';
import { LOCAL_STORAGE_KEY } from 'constants';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Modal from 'components/Modal';
import Button from 'components/Button';
import UserProfile from 'components/UserProfile';
import { ProfileIcon } from 'assets/icons';
import { color } from 'theme';
import { AuthWrapper, UserNameWrapper } from './style.js';
import LoginForm from './LoginForm';

export const AuthContainer = () => {
  const isUserAuth = !!useSelector((state) => state.user.data.token);
  const userName = useSelector((state) => state.user.data.email);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isUserProfileShow, setIsUserProfileShow] = useState(false);
  const user = localStorage.getItem(`${LOCAL_STORAGE_KEY}access-token`);

  // Log google analytics events
  const analytics = getAnalytics();

  const handleShowModal = useCallback(() => {
    setIsModalShow(true);
  }, []);

  const handleShowProfile = useCallback(() => {
    if (!isUserProfileShow) {
      logEvent(analytics, 'select_content', {
        content_type: 'Click on User profile menu',
        content_id: userName,
      });
    }

    setIsUserProfileShow((prevState) => !prevState);
  }, [analytics, isUserProfileShow, userName]);

  const handleCloseModal = useCallback(() => {
    setIsModalShow(false);
  }, []);

  return (
    <>
      <UserProfile isOpen={isUserProfileShow} onClose={handleShowProfile} />
      <AuthWrapper>
        <Modal onClose={handleCloseModal} show={isModalShow}>
          <LoginForm onClose={setIsModalShow} show={isModalShow} />
        </Modal>
        {!!userName || !!user ? (
          <UserNameWrapper onClick={handleShowProfile}>
            <ProfileIcon color={color.red} />
          </UserNameWrapper>
        ) : null}

        {!isUserAuth ? (
          <Button onClick={handleShowModal} title={LOGIN} />
        ) : null}
      </AuthWrapper>
    </>
  );
};
