import {
  championshipLeagues,
  UPCOMING_GAMES_ITEM,
  TODAYS_GAMES_ITEM,
} from 'constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGetSortedLeaguesList = (leagues) => {
  const isUserAuth = !!useSelector((state) => state.user.data.token);
  const userId = useSelector((state) => state.user.data.user_id);

  let leaguesList = useMemo(() => [], []);
  const filteredLeagueList = useMemo(() => [], []);
  const list = useMemo(() => [], []);

  useMemo(() => {
    leagues.forEach((league) => {
      if (!championshipLeagues.includes(league.league_name)) {
        filteredLeagueList.push({
          label: league.league_name,
          value: league.league_id.toString(),
        });
      }
      list.push({
        label: league.league_name,
        value: league.league_id.toString(),
      });
    });
  }, [filteredLeagueList, leagues, list]);

  leaguesList = [UPCOMING_GAMES_ITEM, ...list];

  if (isUserAuth && userId) {
    leaguesList.unshift({
      label: 'Favorites',
      value: userId,
    });
  }

  leaguesList = [TODAYS_GAMES_ITEM, ...leaguesList];

  return {
    leaguesList,
    filteredLeagueList,
  };
};
