import { ROUTES, LOCAL_STORAGE_KEY, queryOptions } from 'constants';
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import MainPage from 'pages/MainPage/MainPage';
import { fetchToken } from 'firebase.js';
import ChampionshipPage from 'pages/Championship/ChampionshipPage';
import RestorePassword from 'pages/RestorePassword/RestorePassword';
import Signup from 'pages/Signup/Signup';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import CustomEvents from 'pages/CustomEvents/CustomEvents';
import { UpcomingGames } from 'pages/UpcomingGames';
import Policy from 'pages/Policy/Policy';
import { getSiteInfoConfigAction } from 'redux/config/actionCreators.js';
import { setUserInfoAction } from 'redux/user/actionCreators.js';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from './ThemeProvider';
import { Wrapper } from './Wrapper';

const hostName = window.location.hostname;
const siteUrl = hostName === 'localhost' ? 'downkount.com' : hostName;

const App = () => {
  const dispatch = useDispatch();
  const user = localStorage.getItem(`${LOCAL_STORAGE_KEY}access-token`);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: queryOptions,
    },
  });

  useEffect(() => {
    if (user) {
      const parsedUserInfo = JSON.parse(user);
      dispatch(setUserInfoAction(parsedUserInfo));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      const parsedUserInfo = JSON.parse(user);

      if (parsedUserInfo.user_id) {
        fetchToken({ userId: parsedUserInfo.user_id, siteUrl: hostName });
      }
    }
  }, [user]);

  useEffect(() => {
    dispatch(getSiteInfoConfigAction(siteUrl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer autoClose={5000} />
      <ErrorBoundary>
        <Wrapper>
          <ThemeProvider>
            <Routes>
              <Route element={<MainPage />} exact path="/" />
              <Route
                element={<ChampionshipPage />}
                exact
                path={`/${ROUTES.CHAMPIONSHIPS}`}
              />
              <Route
                element={<RestorePassword />}
                exact
                path={`/${ROUTES.RESTORE_PASSWORD}`}
              />
              <Route element={<Signup />} exact path={`/${ROUTES.SIGNUP}`} />
              <Route
                element={<ChangePassword />}
                exact
                path={`/${ROUTES.CHANGE_PASSWORD}`}
              />
              <Route
                element={<CustomEvents />}
                exact
                path={`/${ROUTES.CUSTOM_EVENTS}`}
              />
              <Route
                element={<UpcomingGames />}
                exact
                path={`/${ROUTES.UPCOMING_GAMES}`}
              />
              <Route element={<Policy />} exact path={`/${ROUTES.POLICY}`} />
            </Routes>
          </ThemeProvider>
        </Wrapper>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
