import React, { memo } from 'react';

import { CountdowItem, CountdowItemNumber, CountdowItemDesc } from '../style';

const Hours = ({ hours }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {hours >= 0 ? (
      <CountdowItem>
        <CountdowItemNumber>
          {hours < 10 ? '0' + hours : hours}
        </CountdowItemNumber>
        <CountdowItemDesc>{hours === 1 ? 'hour' : 'hours'}</CountdowItemDesc>
      </CountdowItem>
    ) : null}
  </>
);

function arePropsEqual(prevProps, nextProps) {
  return prevProps.hours === nextProps.hours;
}

export default memo(Hours, arePropsEqual);
