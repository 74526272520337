import styled from 'styled-components';
import { color, device } from 'theme';

export const StyledTitle = styled.h1`
  color: ${color.red};
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const LargeText = styled.p`
  color: ${color.white};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
  text-align: center;

  @media ${device.tablet} {
    font-size: 3rem;
    line-height: 3rem;
  }
`;

export const SecondaryText = styled.span`
  color: ${color.white};
  line-height: 1.1rem;
  font-size: 1.2rem;
  text-align: center;

  @media ${device.tablet} {
    font-size: 2rem;
    line-height: 2.1rem;
  }
`;

export const Text = styled.p`
  color: ${color.white};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1rem;
  text-align: center;
`;
