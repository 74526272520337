import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { color } from 'theme';

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 10px;
  outline: none !important;
`;

const StyledSelect = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'transparent',
    borderBottom: `1px solid ${color.red}`,
    borderRadius: 'none',
    maxWidth: '250px',
    minWidth: '200px',
    width: '50%',
    color: '#ffffff',
    marginBottom: 'none',
    cursor: 'pointer',
    outline: 'none !important',
    boxShadow: 'none',
    opacity: isDisabled ? 0.5 : 1,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? '#000000' : '#ffffff',
    backgroundColor:
      isFocused || isSelected ? `${color.red}` : 'rgba(0, 0, 0, 0.9)',
    pointerEvents: isSelected ? 'none' : 'auto',
  }),
};

const selectorColors = {
  primary: 'transparent',
  primmary25: 'transparent',
};

const SelectTitles = styled.span`
  color: ${color.greyText};
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-right: 1rem;
  font-weight: 300;
`;

export const Selector = ({
  isDisabled = false,
  placeholder = 'Select',
  options,
  defaultValue,
  onChange,
  value,
  title = '',
}) => {
  const [isSearch, setIsSearch] = useState(true);

  return (
    <StyledLabel>
      {title ? <SelectTitles>Team</SelectTitles> : null}
      <Select
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        blurInputOnSelect={true}
        components={{ IndicatorSeparator: () => null }}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isSearchable={isSearch}
        onChange={onChange}
        onMenuClose={() => setIsSearch(false)}
        onMenuOpen={() => setIsSearch(true)}
        options={options}
        placeholder={placeholder}
        styles={StyledSelect}
        theme={{
          colors: selectorColors,
        }}
        value={value}
      />
    </StyledLabel>
  );
};
