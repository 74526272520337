import { LOCAL_STORAGE_KEY } from 'constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { useMutation } from 'react-query';
import types from 'redux/types';
import api from 'api/api.js';
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Button from 'components/Button';

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

export const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const analytics = getAnalytics();

  let userInfo = {};

  const sendGoogleAuthData = useMutation(
    (data) => api.user.loginWithUser(data),
    {
      onError: () => {
        toast.error(
          'An error occurred while login. Please, use another login method or try again later.'
        );

        logEvent(analytics, 'select_content', {
          content_type: 'error',
          content_id: `An error occurs during login with Google. Site url: ${window.location.href}`,
        });
      },
      onSuccess: ({ data }) => {
        const authData = {
          ...userInfo,
          email: data?.data?.email,
          user_id: data?.data?.id,
          firebaseToken: data?.data?.firebaseToken,
        };

        dispatch({
          type: types.USER_LOGIN_SUCCESS,
          payload: authData,
        });

        localStorage.setItem(
          `${LOCAL_STORAGE_KEY}access-token`,
          JSON.stringify(authData)
        );
      },
    }
  );

  const onSuccess = (res) => {
    const authData = {
      email: res?.profileObj?.email,
      familyName: res?.profileObj?.familyName,
      givenName: res?.profileObj?.givenName,
      name: res?.profileObj?.name,
      googleId: res?.profileObj?.googleId,
    };

    userInfo.googleId = res?.profileObj?.googleId;
    userInfo.token = res?.accessToken;

    sendGoogleAuthData.mutate(authData);
  };

  const onFailure = () => {
    toast.error(
      'An error occurred while login. Please, use another login method or try again later.'
    );
  };

  return (
    <GoogleLogin
      buttonText="Login with Google"
      clientId={clientId}
      cookiePolicy="single_host_origin"
      onFailure={onFailure}
      onSuccess={onSuccess}
      render={(renderProps) => (
        <Button onClick={renderProps.onClick} title="Google Sign In" />
      )}
    />
  );
};
