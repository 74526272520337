import React from 'react';

export const ProfileIcon = ({ color }) => (
  <svg
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 2.75A29.25 29.25 0 1061.25 32 29.28 29.28 0 0032 2.75zm0 2.5a26.73 26.73 0 0120.93 43.38 22.76 22.76 0 00-15.12-12.94 10.59 10.59 0 10-11.62 0 22.76 22.76 0 00-15.12 12.94A26.73 26.73 0 0132 5.25zm0 29.68a8.09 8.09 0 118.09-8.09A8.1 8.1 0 0132 34.93zm0 23.82a26.651 26.651 0 01-19.07-8 20.32 20.32 0 0138.14 0 26.652 26.652 0 01-19.07 8z"
      fill={color || '#000'}
    />
  </svg>
);
