import React, { useState, useEffect, memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';

import GameDate from 'components/GameDate';
import { GameInfoButton, Teams, TvChannel, GameResult } from 'components';
import { CurrentGameButton } from './components/CurrentGameButton';

import 'swiper/swiper-bundle.css';
import {
  StyledSection,
  LeftSideBar,
  RightSideBar,
  SlideContent,
} from './style';

const DashboardOneTeam = ({ games, currentGame, configuration }) => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [progress, setProgress] = useState(games[currentGame]?.event_id);

  //** URL CHANGE ** //
  useEffect(() => {
    setSearchParams(
      (prevValue) => {
        prevValue.set('team', configuration.one_team_name);
        prevValue.set('teamId', configuration.one_team_id);
        prevValue.set('game', progress);
        return prevValue;
      },
      { replace: true }
    );
    // eslint-disable-next-line
  }, [progress]);

  //** FIND event_id FUNCTION */
  const slideChanged = (index) => {
    if (!games) return;
    const oneGame = games[index]?.event_id;
    if (oneGame) {
      setProgress(oneGame);
    }
  };

  return (
    <>
      <LeftSideBar>
        <div className="swiper-button-prev-unique" />
      </LeftSideBar>
      <StyledSection>
        <Swiper
          grabCursor={true}
          id="main-slider"
          initialSlide={currentGame}
          modules={[Navigation, Mousewheel]}
          mousewheel={true}
          navigation={{
            prevEl: '.swiper-button-prev-unique',
            nextEl: '.swiper-button-next-unique',
          }}
          onSlideChange={(swiper) => {
            slideChanged(swiper.activeIndex);
          }}
          slidesPerView={1}
          tag="section"
          wrapperTag="ul"
        >
          {games?.map((game) => (
            <SwiperSlide key={game.event_id} tag="li">
              <SlideContent>
                <Teams
                  eventType={game?.event_type}
                  location={game.event_location}
                  opponent={game.event_text}
                  team={configuration.one_team_name}
                />
                <GameInfoButton
                  city={game.location_city}
                  date={game.event_start}
                  opponent={game.event_text}
                  region={game.stadium_country}
                  stadium={game.stadium_name}
                  stadiumAddress={game.stadium_address}
                  stadiumLocation={{
                    lat: game?.stadium_lat,
                    lng: game?.stadium_lng,
                  }}
                  team={configuration.one_team_name}
                  timeZone={game.stadium_time_zone}
                  zipCode={game.stadium_zip_code}
                />
                <GameDate
                  eventState={game.event_state}
                  eventTime={game.event_start}
                />
                <TvChannel channel={game.event_tv_channel} />
                <GameResult
                  date={game.event_start}
                  eventState={game.event_state}
                  result={game.event_outcome}
                />
                <CurrentGameButton />
              </SlideContent>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledSection>
      <RightSideBar>
        <div className="swiper-button-next-unique" />
      </RightSideBar>
    </>
  );
};

export default memo(DashboardOneTeam);
