import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  ChoiceTitle,
  ErrorMessage,
  Loading,
  AuthContainer,
  UpcomingGamesHeader,
  DashboardUpcomingGames,
} from 'components';

import {
  BodyContainer,
  MainContainer,
  StyledHeader,
  StyledFooter,
} from 'components/App/style';

export const UpcomingGames = () => {
  const analytics = getAnalytics();
  const { loading: isConfigLoading, error: isConfigError } = useSelector(
    (state) => state.configuration
  );
  const {
    data: leagues,
    error: isLeaguesError,
    loading: leaguesLoading,
  } = useSelector((state) => state.leagues);

  const [searchParams] = useSearchParams();
  const selectedLeague = searchParams.get('league');

  if (isConfigLoading || leaguesLoading) {
    return <Loading />;
  }

  if (isConfigError || isLeaguesError) {
    logEvent(analytics, 'select_content', {
      content_type: 'error',
      content_id: `Unable to load configuration or league for ${window.location.href}`,
    });

    return (
      <ErrorMessage
        title=" Oops... Service currently unavailable. We'are working on it. Please,
      try again later."
      />
    );
  }

  return (
    <BodyContainer>
      <StyledHeader>
        <AuthContainer />
        <UpcomingGamesHeader leagues={leagues} />
      </StyledHeader>
      <MainContainer>
        {!selectedLeague ? <ChoiceTitle title="Select league" /> : null}
        <DashboardUpcomingGames />
      </MainContainer>
      <StyledFooter />
    </BodyContainer>
  );
};
