import { ROUTES } from 'constants/routes.js';
import {
  DEFAULT_TOAST_OPTIONS,
  SIGN_UP,
  FORGOT_PASSWORD,
  LOGIN,
  PASSWORD,
} from 'constants/names.js';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { validateEmail } from 'helpers';
import { gapi } from 'gapi-script';
import CustomInput from 'components/CustomInput';
import types from 'redux/types';
import Button from 'components/Button';
import Link from 'components/Link';
import { AuthWrapper } from '../AuthWrapper';

import { LinksContainer, LoginBtnContainer } from '../style.js';
import { GoogleLoginButton } from './GoogleLoginButton';

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

const LoginForm = ({ onClose, show }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);

  const emailInputHandler = useCallback((inputValue) => {
    setEmailError(false);
    setEmail(inputValue);
  }, []);

  const emailErrorHandler = useCallback(() => {
    if (email !== '' && !validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  }, [email]);

  const loginUserHandler = useCallback(() => {
    if (!email && !password) return;

    if (email && password) {
      dispatch({
        type: types.USER_LOGIN_START,
        payload: {
          email,
          password,
        },
      });
    }
  }, [dispatch, email, password]);

  const isButtonDisabled = !email || !password || emailError;

  const showGoogleFacebookAuth = [
    'downkount-dev.web.app',
    'localhost:3000',
    'localhost:3001',
    'downkount.com',
  ].includes(window.location.host);

  useEffect(() => {
    if (user.error) {
      toast.error(`${user.error}`, DEFAULT_TOAST_OPTIONS);

      dispatch({
        type: types.USER_LOGIN_RESET,
      });
    }
  }, [user.error, dispatch]);

  useEffect(() => {
    if (user.data?.token) {
      onClose(false);
      setEmail('');
      setPassword('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.data?.token, onClose]);

  useEffect(() => {
    if (show) {
      setEmail('');
      setPassword('');
      setEmailError(false);
    }
  }, [show]);

  const login = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        loginUserHandler();
      }
    },
    [loginUserHandler]
  );

  useEffect(() => {
    window.addEventListener('keydown', login);

    return () => window.removeEventListener('keydown', login);
  }, [login]);

  useEffect(() => {
    if (showGoogleFacebookAuth) {
      const startScript = () => {
        gapi.client.init({
          clientId: clientId,
          scope: '',
        });
      };

      gapi.load('client:auth2', startScript);
    }
  }, [showGoogleFacebookAuth]);

  return (
    <AuthWrapper>
      <CustomInput
        isError={emailError}
        onBlur={emailErrorHandler}
        onChange={emailInputHandler}
        placeholder="Email"
        type="email"
        value={email}
      />
      <CustomInput
        isPassword
        onChange={setPassword}
        placeholder={PASSWORD}
        type="password"
        value={password}
      />
      <LinksContainer>
        <Link route={`/${ROUTES.RESTORE_PASSWORD}`} title={FORGOT_PASSWORD} />
        <Link route={`/${ROUTES.SIGNUP}`} title={SIGN_UP} />
      </LinksContainer>
      <LoginBtnContainer>
        {showGoogleFacebookAuth ? <GoogleLoginButton /> : null}
        <Button
          disabled={isButtonDisabled || user.loading}
          onClick={loginUserHandler}
          title={LOGIN}
        />
        {/* {showGoogleFacebookAuth && <FacebookLoginButton />} */}
      </LoginBtnContainer>
    </AuthWrapper>
  );
};

export default LoginForm;
